/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from 'theme-ui'
import { useStaticQuery, graphql } from "gatsby"

const isSafari = () => /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window && !window['safari'] || (typeof window !== 'undefined' && typeof window.safari !== 'undefined' && window.safari.pushNotification));

const scroolForWindows = (collection) => {
  let index = 0;
  let divs;  
  return (evt) => {
    if (!divs) {
      divs = collection.map(cl => document.querySelector(cl))
    }

    const  delta= evt.detail? evt.detail*(-120) : evt.wheelDelta;

    index += (delta > 0) ? 1 : -1;
    index = (index < 0) ? 0 : (divs.length -1 < index) ? divs.length: index;

    if (divs[index]) {
      divs[index].scrollIntoView({behavior: 'smooth'});
    }
  }
}

const theme = {
  fonts: {
    body: 'system-ui, sans-serif',
    heading: '"Avenir Next", sans-serif',
    monospace: 'Menlo, monospace',
  },
  colors: {
    text: '#000',
    background: '#fff',
    primary: '#33e',
  },
  cards: {
    primary: {
      padding: 4,
      borderRadius: 4,
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125), 0 0 8px -1px rgba(0, 0, 0, 0.125)',
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: '1px solid',
      borderColor: 'muted',
    },
  },
}

const Layout = ({ children, collection = [] }) => {
  
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)


  useEffect(() => {
    if (isSafari()) {
      document.querySelector('html').classList.add('safari');
    }
  
    var mac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    let evt = scroolForWindows(collection);

    if (document && !mac) {
      let index = 0;
      let divs;
      
      
      document.addEventListener('mousewheel', evt)
    }

    return () => {
      document.querySelector('html').classList.remove('safari');
      document.removeEventListener('mousewheel', evt);
    }

  })


  return (
    <ThemeProvider theme={theme}>
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      <div
        style={{
          margin: `0 auto`,
          width: `100vw`,
          position: 'relative'
        }}
      >
        <main>{children}</main>
        {/* <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer> */}
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
